<template>
  <div>
    {{ symbol }}
  </div>
</template>

<script>

export default {
    name: 'StockHero',
    components: {
    },
    props: {
		symbol: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			
		};
	}
}
</script>
