<template>
  <div>
    <StockHero :symbol="symbol" />
  </div>
</template>

<script>
import StockHero from "@/components/markets/StockHero.vue";

export default {
	name: "StockPage",
	components: {
		StockHero,
	},
	data() {
		return {
			symbol: this.$route.params.slug.toUpperCase(),
			headParams: {
				title: "",
				description: "",
			},
		};
	},
	created() {
		// Check if market exists, if not redirect 404
		if (this.symbol !== "FIT") {
			this.$router.push({ name: "404" });
		}
	},
	mounted() {
		this.updateHead();
		document.dispatchEvent(new Event("x-app-rendered"));
	},
	methods: {
		updateHead() {
			this.headParams.title = this.symbol + " Fitbit Pre-Market Trading";
			this.$emit("updateHead");
		},
	},
	head() {
		return {
			title: {
				inner: this.headParams.title,
			},
			meta: [
				{
					name: "description",
					content: this.headParams.description,
					id: "desc",
				},
				{ property: "og:type", content: "website", id: 'og-type' },
				{ property: "og:title", content: this.headParams.title, id: 'og-title' },
				{ property: "og:description", content: this.headParams.description, id: 'og-description' },
				{
					property: "og:image",
					content: "https://www.morpher.com/img/og_facebook.png",
					id: 'og-image'
				},
				{ property: "og:url", content: "https://www.morpher.com/", id: 'og-url' },
				{ property: "twitter:card", content: "summary_large_image", id: 'twitter-card' },
				{ property: "twitter:site", content: "@morpher_io", id: 'twitter-site' },
				{
					property: "twitter:image",
					content: "https://www.morpher.com/img/og_twitter.png",
					id: 'twitter-image'
				},
			],
			link: [
				{
					rel: "canonical",
					href: "https://www.morpher.com" + this.$route.path,
					id: "canonical",
				},
			],
		};
	},
};
// Fetching market name from backend

// Market name shortening script, remove any of the following
// Ltd.
// Limited
// Inc.
// Incorporated
// Corp.
// Corporation
// Company
// Co.
// Holding
// Holdings
// Group
// The
// (the)
// ,
</script>
